import React, { useState, useEffect } from "react";
import Layout from "./layout/Layout";
import FileUpload from "./shared/Fileupload/FileUpload";
import { Tab, Tabs, } from "react-bootstrap";
import "./shared/Fileupload/Analysis.scss";
import { useTranslation } from "react-i18next";
import { AnalysisService } from "../../src/utils/services/analysis.service";
import { ApiService } from "../../src/utils/services/api";
import jwt_decode from "jwt-decode";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useStore } from "../../src/Store";
import RoleType from "../utils/constants/RoleType";
import { FaRedo } from "react-icons/fa";
import Loader from "../containers/Loader";
import {
    Container,
    Grid,
    makeStyles,
    Paper,
    Typography
} from "@material-ui/core";

const initialValues = {
    admin_comments: "",
};
const savedValues = {
    admin_comments: "",
};
const validationSchema = Yup.object({
});
const apiService = new ApiService();
const analysisService = new AnalysisService();

export default function OperationAnalysis() {
    const [key, setKey] = useState("data-analysis");
    const { t, i18n } = useTranslation();
    const [lang, setLang] = useState("");
    const { tenant } = useStore();
    const [User, setUser] = useState("");
    const [EmbedUrl, setEmbedUrl] = useState("");
    const location = useLocation();
    const [formValues, setFormValues] = useState(null);
    const [userType, setUserType] = React.useState("user");
    const [editAdmin, setEditAdmin] = useState(false);
    const [index, setIndex] = useState(0);
    const [cognitoTenant, setCognitoTenant] = useState(null);

    const [adminLoader, setAdminLoader] = useState(false);
    const QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");
    const embed_options = {
        url: "",
        container: "",
        // scrolling: "yes",
        // height: "AutoFit",
        // resizeHeightOnSizeChangedEvent: false,
        // iframeResizeOnSheetChange: false, // use this option in combination with height: AutoFit, to allow iframe height to resize dynamically, based on sheet height, on changing sheets.
        // width: "100%",
        // withIframePlaceholder: true,
        // // locale: "en-US",
        footerPaddingEnabled: true,
        // // sheetId: 'YOUR_SHEETID',  // use this option to specify initial sheet id to load for the embedded dashboard
        // sheetTabsDisabled: true, // use this option to enable or disable sheet tab controls in dashboard embedding
        // printEnabled: true, // use this option to enable or disable print option for dashboard embedding
        // undoRedoDisabled: true, // set this option to true to disable undo and redo buttons for dashboard embedding
        // resetDisabled: true, // set this option to true to disable reset button for dashboard embedding
        // // defaultEmbeddingVisualType: TABLE // this option only applies to experience embedding and will not be used for dashboard embedding
    };

    const changeLang = (val) => {
        setLang(val);
        sessionStorage.setItem("lang", val);
        i18n.changeLanguage(val);
    };

    useEffect(() => {
        getVerifier();
        if (key === "data-analysis" && tenant && User && location) {
            getQuickSightAnalysis();
        }
        if (i18n.language) {
            changeLang(i18n.language);
        }
    }, [i18n.language, User, tenant, location, userType]);

    useEffect(() => {
        if (tenant && location) {
            getAdminComments();
        }

        if (location.pathname) {
            setKey("data-analysis");
        }
    }, [location, tenant, formValues]);

    const getVerifier = async () => {
        try {
            const response = await apiService.getToken();
            const decoded = jwt_decode(response);
            setUser(decoded["cognito:username"]);
            setCognitoTenant(decoded["cognito:groups"][0]);
            setUserType(decoded["custom:role"]);
        } catch (err) { }
    };

    const getQuickSightAnalysis = async () => {
        getVerifier();
        const params = {
            user: User,
            analysis: location.pathname.split("/")[1],
            tenant: cognitoTenant,
            tenant_dashboard: tenant,
            userType: userType,
        };
        const response = analysisService.getQuickSightDashboardAnalysis(params);
        const res = await response;

        document.querySelector("#experience-container").innerHTML = ``;
        const { createEmbeddingContext } = QuickSightEmbedding;
        const embeddingContext = await createEmbeddingContext({
          onChange: (changeEvent, metadata) => {
            console.log("Context received a change", changeEvent, metadata);
          },
        });
        const frameOptions = Object.assign({}, embed_options);
        frameOptions["url"] = res.data.EmbedUrl
        frameOptions["container"] = document.getElementById("experience-container");
        await embeddingContext.embedDashboard(frameOptions);

        // setEmbedUrl(res.data.EmbedUrl);
    };

    const onSetKey = (k) => {
        setKey(k);
        if (k === "data-analysis" && tenant && User) {
            getQuickSightAnalysis();
        }
    };

    const getAdminComments = async () => {
        const params = {
            analysis: location.pathname.split("/")[1],
        };
        const response = await analysisService.getAdminComments(params, tenant);
        if (response) {
            savedValues["admin_comments"] = response.data.comments;
            setFormValues(savedValues);
        }
    };

    const saveAdminComments = async (e) => {
        setAdminLoader(true);
        const data = {
            comments: e.admin_comments,
            analysis: location.pathname.split("/")[1],
        };
        const response = await analysisService.postAdminComments(data, tenant);
        if (response) {
            setAdminLoader(false);
            setFormValues(initialValues);
            getAdminComments();
            Swal.fire({
                title: t("comments_submitted_successfuly"),
                confirmButtonColor: "#D57B85",
                confirmButtonText: "OK",
                allowOutsideClick: false,
            })
            setEditAdmin(false);
        }
    };

    const onEdit = async (formikType) => {
        if (formikType === "comments") {
            setEditAdmin(true);
        }
    };

    return (
        <Layout>
            <div className="col-12 px-2 child-layout">
                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    validateOnMount
                >
                    {(formik) => {
                        return (
                            <Form className="text-purple p-1 h-100">
                                <Grid container >
                                    {/* タイトル */}
                                    <Grid item xs={4} className="grid-padding">
                                        <div className="d-flex justify-content-between  " >
                                            <div className="p-1 d-flex flex-column">
                                                <div className="d-flex">
                                                    <h3 className="mb-0 text-body">
                                                        <b>{t("Operation")}</b>
                                                    </h3>
                                                </div>
                                                <p className="text-body mb-0">{t("analysis_operation")}</p>
                                            </div>
                                        </div>
                                    </Grid>
                                    {/* コメント部分 */}
                                    <Grid item xs={7} className="grid-padding">
                                        <label
                                            type="text-in"
                                            className="clr-14213D pt-1"
                                            htmlFor="admin_comments"
                                        >
                                            <h4>
                                                <b>{t("admin_comments")}</b>
                                            </h4>
                                        </label>
                                        <Field
                                            as="textarea"
                                            id="admin_comments"
                                            name="admin_comments"
                                            className="admin_comments" // これで横100％になる
                                            rows={3}
                                            disabled={
                                                ![RoleType.ADMIN, RoleType.AGENT, RoleType.CONSULTANT].includes(
                                                    userType
                                                ) || !editAdmin
                                            }
                                            placeholder={t("admin_comments")}
                                        />
                                        <div className="text-danger">
                                            <ErrorMessage
                                                name="admin_comments"
                                                className="text-danger"
                                            />
                                        </div>
                                    </Grid>

                                    {/* 編集ボタン */}
                                    <Grid item xs={1} className="grid-padding">
                                        {[RoleType.ADMIN, RoleType.AGENT, RoleType.CONSULTANT].includes(
                                            userType
                                        ) && (
                                                <div className=" align-self-end  text-nowrap">
                                                    {!editAdmin ? (
                                                        <button
                                                            className="btn btn-14213D w-100"
                                                            onClick={() => onEdit("comments")}
                                                        >
                                                            {t("edit")}
                                                        </button>
                                                    ) : adminLoader ? (
                                                        <Loader />
                                                    ) : (
                                                        <button
                                                            className="btn btn-14213D w-100"
                                                            onClick={() => saveAdminComments(formik.values)}
                                                        >
                                                            {t("save")}
                                                        </button>
                                                    )}
                                                </div>
                                            )}
                                        <div className="text-danger">
                                            <ErrorMessage
                                                name="admin_comments"
                                                className="text-danger"
                                            />
                                        </div>
                                        <button
                                            className="mt-3 mb-0 bg-white border-0"
                                            onClick={(e) => {
                                                getQuickSightAnalysis();
                                                getAdminComments();
                                            }}
                                        >
                                            <h4 className="mt-0 mb-0 text-dark p-0">
                                                <FaRedo />
                                            </h4>
                                        </button>
                                    </Grid>
                                    {/* URL埋め込み */}
                                    <Grid item xs={12} style={{ height: "100vh" }} className="grid-padding">
                                        <div id="iframeBlock">
                                            <div className="iframe-withoutTab">
                                                <div className="iframeBody">
                                                    {/* <iframe src={EmbedUrl}></iframe> */}
                                                    <div id="experience-container" className="container-dashboard-embedding" style={{ height: 'calc(100vh - 16px)' }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </Layout >
    );
}