import { useState, useEffect } from "react";
import { ClientService } from "./utils/services/client.service";
import useSWR from "swr";

const useCompaniesInfo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const clientService = new ClientService();
  const strToDate = (str, delim) => {
    var arr = str.split(delim);
    return new Date(arr[0], arr[1] - 1, arr[2]);
  };

  const fetchCompaniesInfo = async () => {
    console.log("fetchCompaniesInfo");
    setIsLoading(true);
    try {
      const response = await clientService.listCompany({
        is_get_all: true
      });
      const new_data = response.data?.data.map((c) => {
        return { ...c, startAt: strToDate(c.startAt, "/"), expireAt: strToDate(c.expireAt, "/") };
      });
      setCompanies(new_data);
      setIsLoading(false);
      return new_data;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  const {
    data: fetchedCompanies,
    error,
    mutate,
  } = useSWR("companies", fetchCompaniesInfo, {
    revalidateOnFocus: false,
  });

  useEffect(() => {
    if (fetchedCompanies) {
      setCompanies(fetchedCompanies);
    }
  }, [fetchedCompanies]);

  useEffect(() => {
    if (error) {
      console.log(error);
      // エラーハンドリングの処理を追加する場合はここに記述します
    }
  }, [error]);

  return {
    companies,
    setCompanies,
    mutate,
    isLoading,
  };
};

export default useCompaniesInfo;
