import React, { useEffect, useState, useCallback } from "react";
import Layout from "./layout/Layout";
import { Tab, Tabs } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from "react-i18next";
import { FaFileCsv } from "react-icons/fa";
import Consultant from "./Consultant";
import ClientCompany from "./ClientCompany";
import ClientUser from "./ClientUser";
import Agent from "./Agent";
import Auth from "@aws-amplify/auth";
import RoleType from "../utils/constants/RoleType";
import { ApiService } from "../../src/utils/services/api";
import jwt_decode from "jwt-decode";

const AccountManagement = () => {
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState("");
  const [bulkAgentCreateshow, setBulkAgentCreateShow] = useState(false);
  const [bulkConsultantCreateshow, setBulkConsultantCreateshow] = useState(false);
  const [bulkCompanyCreateshow, setBulkCompanyCreateshow] = useState(false);
  const [bulkUserCreateshow, setBulkUserCreateshow] = useState(false);
  const [activeTenant, setActiveTenant] = useState("");
  const [activeAgent, setActiveAgent] = useState("");
  const [activeConsultants, setActiveConsultants] = useState("");
  const [userType, setUserType] = useState();
  const [uploadError, setUploadError] = useState([]);
  const [createError, setCreateError] = useState([]);
  const [bottomError, setBottomError] = useState("");

  const handleAgentCsv = () => {
    setBulkAgentCreateShow(true);
  }

  useEffect(() => {
    (async () => {
      const data = await Auth.currentUserInfo();
      if (data) {
        setUserType(data.attributes["custom:role"]);
      }
    })();
  }, [userType]);

  useEffect(() => {
    switch (userType) {
      case RoleType.ADMIN:
        setActiveTab("agent");
        break;
      case RoleType.AGENT:
        setActiveTab("consultants");
        break;
      case RoleType.CONSULTANT:
        setActiveTab("client_company");
        break;
    }
  }, [userType]);

  useEffect(() => {
    setUploadError([]);
  }, [activeTab]);

  return (
    <Layout>
      <div className="col-12 px-2 child-layout" >
        <div className="pb-2 cus-tab-container">
          <Tabs
            id="uncontrolled-tab-example"
            className="mb-0 d-flex "
            onSelect={(e) => {
              setActiveTab(e);
            }}
            activeKey={activeTab}
          >
            {(userType === RoleType.ADMIN) && (
              <Tab eventKey="agent" title={t("agent")} >
                <Agent setActiveTab={setActiveTab} setActiveAgent={setActiveAgent} bulkAgentCreateshow={bulkAgentCreateshow} setBulkAgentCreateShow={setBulkAgentCreateShow} setActiveTenant={setActiveTenant} activeTab={activeTab} setActiveConsultants={setActiveConsultants} setUploadError={setUploadError} />
              </Tab>
            )}
            {(userType === RoleType.ADMIN || userType === RoleType.AGENT) && (
              <Tab eventKey="consultants" title={t("consultants")}>
                 {
                    (activeTab == "consultants") && (
                      <Consultant activeTab={activeTab} setActiveTab={setActiveTab} activeAgent={activeAgent} setBulkConsultantCreateshow={setBulkConsultantCreateshow} bulkConsultantCreateshow={bulkConsultantCreateshow} setActiveTenant={setActiveTenant} activeConsultants={activeConsultants} setActiveConsultants={setActiveConsultants} setUploadError={setUploadError} setBottomError = {setBottomError}/>
                    )
                  }
              </Tab >
            )}
            {
              (userType === RoleType.ADMIN || userType === RoleType.AGENT || userType === RoleType.CONSULTANT) && (
                <Tab eventKey="client_company" title={t("client_company")}>
                  {
                    (activeTab == "client_company") && (
                      <ClientCompany setActiveTab={setActiveTab} activeTenant={activeTenant} setBulkCompanyCreateshow={setBulkCompanyCreateshow} bulkCompanyCreateshow={bulkCompanyCreateshow} setActiveTenant={setActiveTenant} activeTab={activeTab} setActiveAgent={setActiveAgent} setActiveConsultants={setActiveConsultants} setUploadError={setUploadError} setBottomError = {setBottomError}/>
                    )
                  }
                </Tab>
              )
            }
            {
              (userType === RoleType.ADMIN || userType === RoleType.AGENT || userType === RoleType.CONSULTANT) && (
                <Tab eventKey="client_user" title={t("client_user")}>
                  {
                    (activeTab == "client_user") && (
                      <ClientUser setBulkUserCreateshow={setBulkUserCreateshow} bulkUserCreateshow={bulkUserCreateshow} setActiveTenant={setActiveTenant} activeTab={activeTab} setActiveAgent={setActiveAgent} setUploadError={setUploadError} uploadErrors={uploadError} setCreateError={setCreateError} setBottomError = {setBottomError}/>
                    )
                  }
                </Tab>
              )
            }
          </Tabs >
        </div >

        <div className="csv-container">
          <div className="csv-content">
            {uploadError.length > 0 &&
              <h4 className="error">{t("update_err_title")}</h4>}
            {uploadError.length > 0 &&
              <h4 className="error">{t("update_err_total", { errRowCount: uploadError.length })}</h4>}
            {uploadError.length > 0 &&
              uploadError.map((error) => {
                switch (error.type) {
                  case "required":
                    return (
                      <h4 className="error"><span style={{ textTransform: 'none' }}>
                        {t("update_item_err", { line_number: error.index + 1, item_name: t(error.item) + "(" + error.column + ")" })}
                      </span></h4>
                    );
                  case "userTypeInvalid":
                    return (
                      <h4 className="error"><span style={{ textTransform: 'none' }}>
                        {t("user_type_content_err", { line_number: error.index + 1 })}
                      </span></h4>
                    );
                  case "tenantInvalid":
                    return (
                      <h4 className="error"><span style={{ textTransform: 'none' }}>
                        {t("tenant_content_err", { line_number: error.index + 1 })}
                      </span></h4>
                    );
                  case "emailInvalid":
                    return (
                      <h4 className="error" ><span style={{ textTransform: 'none' }}>
                        {t("email_content_err", { line_number: error.index + 1 })}
                      </span></h4>
                    );
                  case "duplicated":
                    return (
                      <h4 className="error"><span style={{ textTransform: 'none' }}>
                        {t("update_item_duplicated_err", { index1: error.index1 + 1, index2: error.index2 + 1 })}
                      </span></h4>
                    );
                  case "email_in_use_err":
                    return (
                      <h4 className="error"><span style={{ textTransform: 'none' }}>
                        {t("email_in_use_err", { line_number: error.index + 1, email: error.email })}
                      </span></h4>
                    );
                  default:
                    return null;
                }
              })}
            {createError.length > 0 &&
              <h4 className="error">{t("create_err_title")}</h4>}
            {createError.length > 0 &&
              <h4 className="error">{t("create_err_tenant", { company_name: createError[0].tenant })}</h4>}
            {createError.length > 0 &&
              createError.map((error) => (
                <h4 className="error" ><span STYLE='text-transform:none'>{
                  t("create_err_content", { user_name: `${error.name}       `, email:error.email})
                }</span></h4>
              ))}
            {bottomError != "" && <h4 className="error">{bottomError}</h4>}
            <h5>{t("csv_upload")}</h5>
          </div>
          <div className="d-flex align-items-center gap-4 flex-wrap m-auto justify-content-center ">
            {(userType === RoleType.ADMIN) && (
              <div>
                <div className="d-flex justify-content-end mb-2">
                  <button
                    className="btn btn-44403f op-100"
                    onClick={() => handleAgentCsv()}
                  >
                    <span className="pt-1">{t("agent")} </span>
                    <FaFileCsv className="align-text-middle" />{" "}
                  </button>
                </div>
              </div>
            )}
            {(userType === RoleType.ADMIN || userType === RoleType.AGENT) && (
              <div>
                <div className="d-flex justify-content-end mb-2">
                  <button
                    className="btn btn-44403f op-100"
                    onClick={() => setBulkConsultantCreateshow(true)}
                  >
                    <span className="pt-1">{t("consultants")} </span>
                    <FaFileCsv className="align-text-middle" />{" "}
                  </button>
                </div>
              </div>
            )}
            {(userType === RoleType.ADMIN || userType === RoleType.AGENT) && (
              <div>
                <div className="d-flex justify-content-end mb-2">
                  <button
                    className="btn btn-44403f op-100"
                    onClick={() => setBulkCompanyCreateshow(true)}
                  >
                    <span className="pt-1">{t("client_company")} </span>
                    <FaFileCsv className="align-text-middle" />{" "}
                  </button>
                </div>
              </div>
            )}
            {(userType === RoleType.ADMIN || userType === RoleType.AGENT || userType === RoleType.CONSULTANT) && (
              <div>
                <div className="d-flex justify-content-end mb-2">
                  <button
                    className="btn btn-44403f op-100"
                    onClick={() => setBulkUserCreateshow(true)}
                  >
                    <span className="pt-1">{t("client_management")} </span>
                    <FaFileCsv className="align-text-middle" />{" "}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div >
    </Layout >
  );
};
export default AccountManagement;
